import {isFeatureEnabled} from '@github-ui/feature-flags'
import {navigator, session} from '@github/turbo'
import {currentState} from '@github-ui/history'

type State = Record<string, unknown> & {
  turbo?: {restorationIdentifier: string}
  skipTurbo?: boolean
  usr?: {skipTurbo: boolean}
  turboCount?: number
  appId?: string
}

// Use this mock interface to avoid importing ReactAppElement or ProjectsV2 here.
interface AppWithUuid extends Element {
  uuid: string
}

const turboAppIdRestoreEnabled = isFeatureEnabled('turbo_app_id_restore')

// Turbo should restore the page on b/f navigation whenever we cross app boundaries or are going from rails to rails.
session.history.shouldRestore = (state?: State) => {
  if (!turboAppIdRestoreEnabled) return true

  const currentApp = currentAppId()
  const stateAppId = state?.appId
  return currentApp !== stateAppId || (stateAppId === 'rails' && currentApp === 'rails') || !stateAppId
}

const currentAppId = () => {
  const currentApp =
    document.querySelector<AppWithUuid>('react-app') || document.querySelector<AppWithUuid>('projects-v2')

  return currentApp?.uuid || 'rails'
}

// keep Turbo's history up to date with the browser's in case code calls native history API's directly
const patchHistoryApi = (name: 'replaceState' | 'pushState') => {
  const oldHistory = history[name]

  history[name] = function (this: History, state?: State, unused?: string, url?: string | URL | null) {
    const skipTurbo = state?.skipTurbo || state?.usr?.skipTurbo

    // we need to merge the state from turbo with the state given to pushState in case others are adding data to the state
    function oldHistoryWithMergedState(
      this: History,
      turboState: State,
      turboUnused: string,
      turboUrl?: string | URL | null,
    ) {
      const currentTurboCount = currentState().turboCount || 0
      const isTurboNav = name === 'pushState' && state?.turbo

      // The only places that actively sets the appId are:
      //  1. during ReactAppElement connectedCallback.
      //  2. when turbo is pushing a state (turbo navs)
      // We want to make sure the app registers itself in the history state and propagate it between
      // soft navs and other state changes.
      const appId = isTurboNav ? 'rails' : state?.appId || currentState().appId

      // Only turbo navs have the `turbo` key when pushing state.
      const turboCount = isTurboNav ? currentTurboCount + 1 : currentTurboCount

      const mergedState =
        skipTurbo && !turboAppIdRestoreEnabled
          ? {...state, skipTurbo: true, appId}
          : {...state, ...turboState, turboCount, appId}
      oldHistory.call(this, mergedState, turboUnused, turboUrl)
    }

    navigator.history.update(
      oldHistoryWithMergedState,
      new URL(url || location.href, location.href),
      state?.turbo?.restorationIdentifier,
    )
  }
}

patchHistoryApi('replaceState')
patchHistoryApi('pushState')
